/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react";
import {
  Box
} from "@chakra-ui/react";



interface Props {
  children: React.ReactNode
}


export const Layout: React.FC<Props> = ( props ) => {
  return (
    <>
      <Box
        as="main"
        position="relative"
        h="100vh"
        bg="#000000"
      >
        { props.children }
      </Box>
    </>
  )
}
